import React, {useEffect} from "react";

const NotFound = () => {

    useEffect(() => {
        setTimeout(() => {
            window.location.href = 'https://google.com';
        }, 10000);
    });

    return (
        <h1>Page not found.</h1>
    )
};

export default NotFound;