import React from "react";
import { Container } from "react-bootstrap";
import logo from "../bizooy-logo.png";

const Layout = (props) => {
    return (
        <div>
          <div className="text-center mt-4">
          <img src={logo} style={{maxHeigth: '200px', width: 'auto'}} />
          </div>
          <Container className="mt-4">{props.children}</Container>
          <p className="text-center mt-4">Bizooy {new Date().getFullYear()}. All rights reserved.</p>
        </div>
      );
};


export default Layout;
