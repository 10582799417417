import React, { useEffect, useState } from "react";
import Layout from "./layout";
import {
    useParams
} from "react-router-dom";
import FeedbackService from "../api/feedback.service";
import ReactStars from "react-rating-stars-component";
import { Button, Form, Alert } from "react-bootstrap";

const Feedback = () => {

    let { id } = useParams();
    const [token, setToken] = useState(null);
    const [auth, setAuth] = useState(null);
    const [rating, setRating] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const [comment, setComment] = useState('');
    const [socialProfile, setSocialProfile] = useState('');
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setToken(id);

        async function getBusiness() {
            await FeedbackService.authenticate({ id: id }).then((authentication) => {
                setAuth(authentication);
                setRating(null);
                setComment('');
            }).catch(() => {
                window.location.href = 'https://bizooy.com';
            });
        }

        getBusiness();
    }, [id]);

    const ratingChanged = (newRating) => {
        setRating(newRating);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const feed = {
            company_id: auth.company_id,
            user_id: auth.user_id,
            feedback: {
                comment: comment,
                social_profile: socialProfile,
                rating: rating
            }
        }

        try{
            const resp = await FeedbackService.publish(feed);
            setSubmitted(true);
            setTimeout(() => {
                window.location.href = redirect;
            }, 4000);

        }
        catch(e){
            console.log(e);
        }
    };

    const handleChange = async (event) => {
        if (event.target.value.length > 20) {
            setComment(event.target.value);
        }
    }

    const setProfile = (profile, redirect_url) => {
        setSocialProfile(profile);
        setRedirect(redirect_url);
    }

    return (
        <Layout>
            {auth &&
                <div className="row">
                    {!submitted &&
                        <div className="col-md-12">
                        <h2>Hello {auth.customer_name}, </h2>
                        <h4>Please let us know how was your experience with <b><span className="text-success">{auth.business_name}</span></b>:</h4>
                        <hr/>

                        <Form onSubmit={handleSubmit} className="mt-2">
                            <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={24}
                                activeColor="#ffd700"
                            />

                            {rating &&
                                <div className="mt-2">
                                    <Form.Group controlId="comments">
                                        <Form.Label>Please describe your experience in at least 20 words.</Form.Label>
                                        <Form.Control as="textarea" rows={3} onChange={handleChange} />
                                    </Form.Group>


                                    <div className="row">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8 text-center">
                                            {comment && rating > 3 &&
                                                <p><small>Submit and get redirect to one of the social profiles.</small></p>
                                            }
                                            {comment && auth.social_profiles.facebook.url !== "" && rating > 3 &&
                                                <button className="btn btn-primary btn-block mt-1 mb-1" type="submit" value={"facebook"} onClick={() => setProfile('facebook', auth.social_profiles.facebook.url)}>Facebook</button>
                                            }
                                            {comment && auth.social_profiles.google.url !== "" && rating > 3 &&
                                                <button className="btn btn-success btn-block mt-1 mb-1" type="submit" value={"google"} onClick={() => setProfile('google', auth.social_profiles.google.url)}>Google</button>
                                            }
                                            {comment && auth.social_profiles.yelp.url !== "" && rating > 3 &&
                                                <button className="btn btn-danger btn-block mt-1 mb-1" type="submit" value={"yelp"} onClick={() => setProfile('yelp', auth.social_profiles.yelp.url)}>Yelp</button>
                                            }
                                            {comment && auth.social_profiles.custom.url !== "" && rating > 3 &&
                                                <button className="btn btn-light btn-block mt-1 mb-1" type="submit" value={"custom"} onClick={() => setProfile('custom', auth.social_profiles.custom.url)}>Custom</button>
                                            }
                                            {comment && rating <= 3 &&
                                                <button className="btn btn-light btn-block mt-1 mb-1" type="submit" value={"custom"} onClick={() => setProfile('custom', auth.social_profiles.custom.url)}>Submit</button>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                            }


                        </Form>


                    </div>
                    }

                    {submitted &&
                    <div className="col-md-12">
                        <Alert variant="success">Please wait while we redirect you. Thanks!
                            </Alert>
                            </div>
                    }
                </div>
            }
        </Layout>
    )

};

export default Feedback;
