import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import "./App.css";
import Feedback from "../components/feedback";
import NotFound from "../components/notfound";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/:id">
          <Feedback />
        </Route>
        <Route exact path="/">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
