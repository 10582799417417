import { axiosInstance, authedAxiosInstance } from "./api.service";

const FeedbackService = {
  authenticate: async ({id}) => {
    const response = await axiosInstance.get("/feedback/authenticate/" + id);
    return response.data;
  },
  publish: async (feedback) => {
    const response = await authedAxiosInstance.post("/feedback/publish", feedback);
    return response.data;
  },
};

export default FeedbackService;